@use 'styles/variables' as var;

:root {
    --cds-primary-font: #{var.$primaryfont};

    --cds-small-pad: #{var.$smallPad};
    --cds-regular-pad: #{var.$regularPad};
    --cds-medium-pad: #{var.$mediumPad};
    --cds-large-text: #{var.$extralarge};

    --cds-secondary-background: #{var.$white};
    --cds-text-color: #{var.$black};
    --cds-toast-title-gap: #{var.$regularPad};

    --cds-primary-color: #{var.$slateblue};
    --cds-success-color: #{var.$success-green};
    --cds-error-color: #{var.$notification-red};
    --cds-warning-color: #{var.$yellow};

    --cds-snackbar-info-color: #{var.$snackbar-info};
    --cds-snackbar-success-color: #{var.$snackbar-success};
    --cds-snackbar-warning-color: #{var.$snackbar-warning};
    --cds-snackbar-error-color: #{var.$snackbar-error};
    --cds-snackbar-text-color: #{var.$color-grey-200};
    --cds-snackbar-closing-icon-size: 12px;

    cds-toast {
        position: relative;
        top: #{var.$extrabigpad};
    }
}
